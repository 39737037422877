import React from 'react'
import styled from 'styled-components'

const About = ({ className,title = 'about', text = '' }) => {
  return (
    <div>
      <h1 className={className}>{title}</h1>
      <p className={className} dangerouslySetInnerHTML={{ __html: text }}></p>
    </div>
  )
}

export default styled(About)`

`